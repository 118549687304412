import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import { CostCalculatorComponent } from './components/cost-calculator/cost-calculator.component';
import { CurrencyComponent } from './components/currency/currency.component';
import { DeliveryCalculatorComponent } from './components/delivery-calculator/delivery-calculator.component';
import { InfoRowComponent } from './components/info-row/info-row.component';
import { ServiceTitlePipe } from './pipes/service-title.pipe';
import { ShippingCalculatorComponent } from './components/shipping-calculator/shipping-calculator.component';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

@NgModule({
  declarations: [
    CurrencyComponent,
    DeliveryCalculatorComponent,
    CostCalculatorComponent,
    InfoRowComponent,
    ServiceTitlePipe,
    ShippingCalculatorComponent,
  ],
  exports: [
    CurrencyComponent,
    DeliveryCalculatorComponent,
    CostCalculatorComponent,
    InfoRowComponent,
    ShippingCalculatorComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NzToolTipModule,
    NzDescriptionsModule,
    NzGridModule,
    NzSpinModule,
    FormsModule,
    ReactiveFormsModule,
    NzSelectModule,
    NzInputModule,
    NzFormModule,
    RouterModule,
    NzCheckboxModule,
    NzDividerModule,
    NzTableModule,
    NzButtonModule,
    NzTagModule,
    NzEmptyModule,
  ],
})
export class SharedModule {}
