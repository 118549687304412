import { Injectable } from '@angular/core';
import { NzNotificationService, NzNotificationPlacement } from 'ng-zorro-antd/notification';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  placement: NzNotificationPlacement = 'bottomRight';

  constructor(
    private translate: TranslateService,
    private nzNotificationService: NzNotificationService
  ) {

  }

  setDefaultPlacement(placement: NzNotificationPlacement): void {
    this.placement = placement;
  }

  renderBlank(title: string, content?: string): void {
    this.nzNotificationService.blank(
      this.translate.instant(title),
      content || '',
      { nzPlacement: this.placement }
    );
  }

  renderSuccess(title: string, content?: string): void {
    this.nzNotificationService.success(
      this.translate.instant(title),
      content || '',
      { nzPlacement: this.placement }
    );
  }

  renderInfo(title: string, content?: string): void {
    this.nzNotificationService.info(
      this.translate.instant(title),
      content || '',
      { nzPlacement: this.placement }
    );
  }

  renderWarning(title: string, content?: string): void {
    this.nzNotificationService.warning(
      this.translate.instant(title),
      content || '',
      { nzPlacement: this.placement }
    );
  }

  renderError(title: string, content?: string): void {
    this.nzNotificationService.error(
      this.translate.instant(title),
      content || '',
      { nzPlacement: this.placement }
    );
  }
}
