import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleTranslateService {
  constructor(private translateService: TranslateService) {}

  translate(): void {
    const select = document.getElementsByClassName('goog-te-combo')[0] as HTMLSelectElement;
    const targetLang = this.translateService?.store.currentLang;
    console.log('switching to', targetLang);

    if (targetLang === 'ru') {
      select.value = 'ru';
      select.dispatchEvent(new Event('change'));
    }

    if (targetLang === 'en') {
      select.value = 'en';
      select.dispatchEvent(new Event('change'));
    }
  }
}
