<nz-spin [nzSpinning]="loading$ | async" nzSize="large">
  <section class="shipping-calculator">
    <h3>{{ 'SHIPPING_CALCULATOR.TITLE' | translate }}</h3>
    <form nz-form [formGroup]="form" nzLayout="vertical">
      <nz-row nzGutter="14" nzAlign="middle">
        <nz-col [nzXs]="24" [nzMd]="12" [nzLg]="12" [nzXl]="8">
          <nz-form-item>
            <nz-form-label>{{ 'SHIPPING_CALCULATOR.WEIGHT' | translate }}:</nz-form-label>
            <nz-form-control>
              <input nz-input type="text" formControlName="weight" />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col [nzXs]="24" [nzMd]="12" [nzLg]="12" [nzXl]="8">
          <nz-form-item>
            <nz-form-label>{{ 'SHIPPING_CALCULATOR.COUNTRY' | translate }}:</nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="country"
                [nzShowSearch]="true"
                [nzPlaceHolder]="'SHIPPING_CALCULATOR.COUNTRY' | translate"
              >
                <nz-option
                  *ngFor="let country of (countries$ | async)"
                  [nzValue]="country.alpha2"
                  [nzLabel]="country.name"
                  nzCustomContent
                >
                  <span class="notranslate">{{ country.name }}</span>
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col>
          <button
            nz-button
            nzType="primary"
            [nzLoading]="deliveryRatesLoading$ | async"
            (click)="sendForCalcData()"
          >
            {{ 'SHIPPING_CALCULATOR.ACTIONS.CALCULATE' | translate }}
          </button>
        </nz-col>
      </nz-row>
    </form>

    <ng-container *ngIf="(deliveryRates$ | async) as deliveryRates">
      <ng-container *ngIf="deliveryRates.length > 0; else noDeliveryRates">
        <nz-table
          #table
          class="table"
          nzBordered
          [nzData]="deliveryRates"
          [nzLoading]="deliveryRatesLoading$ | async"
          [nzShowPagination]="false"
          [nzFrontPagination]="false"
        >
          <thead>
          <tr>
            <th nzWidth="320px">{{ 'SHIPPING_CALCULATOR.TABLE.SHIPPING_METHOD' | translate }}</th>
            <th>{{ 'SHIPPING_CALCULATOR.TABLE.INTERNATIONAL_SHIPPING_COST' | translate }}</th>
            <th>{{ 'SHIPPING_CALCULATOR.TABLE.SHIPPING_HANDLING_FEE' | translate }}</th>
            <th>{{ 'SHIPPING_CALCULATOR.TABLE.TOTAL_SHIPPING_COST' | translate }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let deliveryRate of table.data">
            <td>
              <nz-row nzAlign="middle">
                <div class="img-wrapper">
                  <img
                    src="{{ shippingImgUrl(deliveryRate.shippingMethod) }}"
                    alt="{{ deliveryRate.shippingMethod }}"
                  >
                </div>
                <span class="shipping-method">{{ deliveryRate.shippingMethod }}</span>
              </nz-row>
            </td>
            <td>¥ {{ deliveryRate.shippingFee }}</td>
            <td>¥ {{ deliveryRate.handlingFee }}</td>
            <td class="total-fee">
              ¥ {{ deliveryRate.totalFee }}
              <span class="total-fee-usd">( ≈ ${{ deliveryRate.totalFeeUsd }})</span>
            </td>
          </tr>
          </tbody>
        </nz-table>
        <nz-tag class="warning-message" nzColor="blue">
          {{ 'SHIPPING_CALCULATOR.WARNING_MESSAGE' | translate }}
        </nz-tag>
      </ng-container>
      <ng-template #noDeliveryRates>
        <nz-empty
          class="no-rates-message"
          nzNotFoundContent="{{ 'SHIPPING_CALCULATOR.NO_RATES_MESSAGE' | translate }}"
        ></nz-empty>
      </ng-template>
    </ng-container>
  </section>
</nz-spin>
