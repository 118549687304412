import { QueryHandlingService } from '@akebono/core';
import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';

import {
  JpyToEurExchangeRateGQL,
  JpyToEurExchangeRateQuery,
  JpyToEurExchangeRateQueryVariables,
  JpyToUsdExchangeRateGQL,
  JpyToUsdExchangeRateQuery,
  JpyToUsdExchangeRateQueryVariables,
} from '../graphql/cartrack-directory-service';

@Injectable({
  providedIn: 'root',
})
export class JpyExchangeRateService {
  constructor(
    private readonly qhs: QueryHandlingService,
    private readonly jpyToEurExchangeRateGQL: JpyToEurExchangeRateGQL,
    private readonly jpyToUsdExchangeRateGQL: JpyToUsdExchangeRateGQL,
  ) {}

  get toEurExchangeRate$(): Observable<number> {
    return this.qhs
      .fetch<JpyToEurExchangeRateQuery, JpyToEurExchangeRateQueryVariables>(
        this.jpyToEurExchangeRateGQL,
        {},
        'network-only',
      )
      .data.pipe(
        map((data) => data.mufgJpyToEurExhangeRate ?? 0),
        shareReplay(1),
      );
  }

  get toUsdExchangeRate$(): Observable<number> {
    return this.qhs
      .fetch<JpyToUsdExchangeRateQuery, JpyToUsdExchangeRateQueryVariables>(
        this.jpyToUsdExchangeRateGQL,
        {},
        'network-only',
      )
      .data.pipe(
        map((data) => data.mufgJpyToUsdExhangeRate ?? 0),
        shareReplay(1),
      );
  }
}
