<nz-spin [nzSpinning]="(loading | async) && !lot" nzSize="large">
  <div *ngIf="(loading | async) && !lot" class="loading-payload"></div>

  <section class="section breadcrumbs">
    <nz-breadcrumb>
      <ng-container *ngFor="let crumb of breadcrumbs">
        <nz-breadcrumb-item>
          <a [routerLink]="crumb.path">{{ crumb.name }}</a>
        </nz-breadcrumb-item>
      </ng-container>
    </nz-breadcrumb>
  </section>

  <section class="section title">
    <h4 nz-typography>
      {{ lot?.lastPayload.Title }}
    </h4>
  </section>

  <section *ngIf="lot" class="section main skiptranslate">
    <div nz-row nzGutter="20">
      <div *ngIf="!mobile" nz-col [nzXs]="9" class="images">
        <app-images [payload]="lot.lastPayload"></app-images>
      </div>
      <div nz-col [nzXs]="mobile ? 24 : 15" class="trade">
        <div nz-row *ngIf="isAuth" nzJustify="space-between" class="buttons">
          <div nz-col>
            <button
              nz-button
              nzType="primary"
              nzShape="round"
              [nzLoading]="loading | async"
              (click)="update()"
            >
              {{ 'AUCTION.UPDATE' | translate }}
            </button>
          </div>
          <div nz-col>
            <button *ngIf="!favId" nz-button nzShape="round" (click)="fav()">
              <i
                *ngIf="(faving | async) === false"
                nz-icon
                [nzType]="'heart'"
                [nzTheme]="'twotone'"
                [nzTwotoneColor]="'gray'"
              ></i>
              <i *ngIf="faving | async" nz-icon [nzType]="'loading'"></i>
              {{ 'AUCTION.ADD_TO_FAV' | translate }}
            </button>
            <button *ngIf="favId" nz-button nzShape="round" (click)="unfav()">
              <i
                *ngIf="(faving | async) === false"
                nz-icon
                [nzType]="'heart'"
                [nzTheme]="'twotone'"
                [nzTwotoneColor]="'#eb2f96'"
              ></i>
              <i *ngIf="faving | async" nz-icon [nzType]="'loading'"></i>
              {{ 'AUCTION.REMOVE_FROM_FAV' | translate }}
            </button>
          </div>
        </div>
        <div class="panel">
          <app-trade-panel
            [lot]="lot"
            [isAuth]="isAuth"
            [exchangeRate]="exchangeRate"
            [timezone]="currentUser?.timezoneOffset"
            [currentTime]="currentTime"
            (amountChange)="onAmountUpdate($event)"
          >
          </app-trade-panel>
          <hr />
          <div class="calculator skiptranslate">
            <app-cost-calculator
              [price]="amount"
              [taxRate]="lot?.lastPayload?.TaxRate"
              [services]="services"
              [isShop]="isShop"
              [allowBid]="isAllowBid()"
              [currentUser]="currentUser"
              [exchangeRate]="exchangeRate"
              [suggestedJpDelivery]="shipmentPrice"
              [bidStep]="lot.bidStep"
              #costCaclc
            >
            </app-cost-calculator>
          </div>
          <hr *ngIf="costCaclc.isRussianUser" />
          <div *ngIf="costCaclc.isRussianUser" class="delivery-calculator skiptranslate">
            <app-delivery-calculator
              [currentUser]="currentUser"
              [costCalc]="costCaclc"
              [exchangeRate]="exchangeRate"
            ></app-delivery-calculator>
          </div>
        </div>
        <div class="panel">
          <app-shipping-calculator></app-shipping-calculator>
        </div>
      </div>
      <div *ngIf="mobile" nz-col [nzXs]="24" class="images mobile">
        <app-images [payload]="lot.lastPayload"></app-images>
      </div>
    </div>
  </section>

  <section *ngIf="lot" class="section info">
    <app-info-panel
      [payload]="lot.lastPayload"
      [QandA]="auctions.showQandA"
      [unasnweredQuestions]="lot.yahooQuestions"
      [bidHistory]="auctions.bidHistoryDetail"
      [messageTemplates]="messageTemplates"
      [isAuth]="isAuth"
    ></app-info-panel>
  </section>
</nz-spin>
