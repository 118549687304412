import { MutationHandlingServiceV2, NotificationService } from '@akebono/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { scrollToTop } from 'src/app/const';
import {
  CrooberCarTypesGQL,
  CrooberOrderCreateGQL,
  CrooberSearchGQL,
  CurrentUser,
  ExchangeRate,
} from 'src/app/graphql/user-service';
import { GoogleTranslateService } from 'src/app/services/google-translate.service';
import { SearchService, SearchServiceEnum } from 'src/app/services/search.service';

@Component({
  selector: 'app-croober-search',
  templateUrl: './croober-search.component.html',
  styleUrls: ['./croober-search.component.scss'],
})
export class CrooberSearchComponent implements OnInit, OnDestroy {
  category: number | null = null;
  search: string | null = null;

  items: any = null;
  currentUser: CurrentUser = null;
  exchangeRate: ExchangeRate = null;

  loading = false;

  filter = {
    new_flag: null,
    free_shipping_flag: null,
    kakaku_low: null,
    kakaku_high: null,
    stars_division: null,
    sort: null,
    q: null,
    page: null,
    pageSize: null,
    category: null,
    partmaker: [],
    details: [],
    model: null,
  };

  subscription: Subscription = null;

  categories = [
    {
      label: 'Шины и диски',
      children: [
        {
          value: 'allRims',
          label: 'Все колеса',
          isLeaf: true,
        },
        {
          value: '01_10_01',
          label: 'Шины',
          isLeaf: true,
        },
        {
          value: '01_10_03',
          label: 'Зимняя шина',
          isLeaf: true,
        },
        {
          value: '01_20_01',
          label: 'Алюминиевые диски',
          isLeaf: true,
        },
        {
          value: '01_20_02',
          label: 'Стальные диски',
          isLeaf: true,
        },
        {
          value: '01_25_01',
          label: 'Шина на диске',
          isLeaf: true,
        },
        {
          value: '01_25_03',
          label: 'Зимняя шина на диске',
          isLeaf: true,
        },
        {
          value: '01_26_05',
          label: 'Прочие',
          isLeaf: true,
        },
      ],
    },
    {
      label: 'Автозвук',
      children: [
        {
          value: '01_30_01',
          label: 'Головное устройство',
          isLeaf: true,
        },
        {
          value: '01_30_02',
          label: 'Динамики',
          isLeaf: true,
        },
        {
          value: '01_30_03',
          label: 'Усилитель',
          isLeaf: true,
        },
        {
          value: '01_40_01',
          label: 'Навигация',
          isLeaf: true,
        },
        {
          value: '01_40_05',
          label: 'Навигация',
          isLeaf: true,
        },
        {
          value: '01_40_10',
          label: 'Монитор',
          isLeaf: true,
        },
        {
          value: '01_40_15',
          label: 'Монитор',
          isLeaf: true,
        },
        {
          value: '01_40_20',
          label: 'Монитор',
          isLeaf: true,
        },
        {
          value: '01_40_25',
          label: 'DVD проигрыватель',
          isLeaf: true,
        },
        {
          value: '01_40_30',
          label: 'Аксессуары',
          isLeaf: true,
        },
        {
          value: '01_40_35',
          label: 'Навигация',
          isLeaf: true,
        },
        {
          value: '01_40_40',
          label: 'Платежная система',
          isLeaf: true,
        },
      ],
    },
    {
      label: 'Тюнинг',
      children: [
        {
          value: '01_50_01',
          label: 'Ходовая часть',
          isLeaf: true,
        },
        {
          value: '01_50_10',
          label: 'Выхлопная система',
          isLeaf: true,
        },
        {
          value: '01_50_15',
          label: 'Электрика',
          isLeaf: true,
        },
        {
          value: '01_50_20',
          label: 'Лампочки',
          isLeaf: true,
        },
        {
          value: '01_50_25',
          label: 'Распорки',
          isLeaf: true,
        },
        {
          value: '01_50_30',
          label: 'Датчики',
          isLeaf: true,
        },
        {
          value: '01_50_35',
          label: 'Турбины',
          isLeaf: true,
        },
        {
          value: '01_50_40',
          label: 'Силовые агрегаты',
          isLeaf: true,
        },
        {
          value: '01_50_45',
          label: 'Система охлаждения',
          isLeaf: true,
        },
        {
          value: '01_50_50',
          label: 'Тормозная система',
          isLeaf: true,
        },
        {
          value: '01_50_55',
          label: 'Кузовные запчасти',
          isLeaf: true,
        },
        {
          value: '01_50_60',
          label: 'Салон',
          isLeaf: true,
        },
        {
          value: '01_50_65',
          label: 'Сиденья',
          isLeaf: true,
        },
        {
          value: '01_50_70',
          label: 'Багажники',
          isLeaf: true,
        },
        {
          value: '01_50_99',
          label: 'Прочее',
          isLeaf: true,
        },
      ],
    },
    {
      label: 'Товары для авто',
      children: [
        {
          value: '01_70_01',
          label: 'Автохимия',
          isLeaf: true,
        },
        {
          value: '01_70_10',
          label: 'Обслуживание',
          isLeaf: true,
        },
        {
          value: '01_70_20',
          label: 'Аксессуары',
          isLeaf: true,
        },
        {
          value: '01_70_99',
          label: 'Прочее',
          isLeaf: true,
        },
      ],
    },
  ];

  makers = [
    {
      label: 'TOYOTA',
      value: '01',
    },
    {
      label: 'NISSAN',
      value: '02',
    },
    {
      label: 'HONDA',
      value: '03',
    },
    {
      label: 'MITSUBISHI',
      value: '04',
    },
    {
      label: 'MAZDA',
      value: '05',
    },
    {
      label: 'SUBARU',
      value: '06',
    },
    {
      label: 'SUZUKI',
      value: '07',
    },
    {
      label: 'DAIHATSU',
      value: '08',
    },
  ];
  models = [];
  partmakers = [];
  details = [];

  total = 0;
  buying = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private translate: TranslateService,
    private searchService: SearchService,
    private crooberSearchGQL: CrooberSearchGQL,
    private crooberCarTypes: CrooberCarTypesGQL,
    private googleTranslate: GoogleTranslateService,
    private crooberOrderCreateGQL: CrooberOrderCreateGQL,
    private mhs: MutationHandlingServiceV2,
    private ns: NotificationService,
  ) {}

  ngOnInit(): void {
    this.searchService.currentService.next(SearchServiceEnum.Croober);
    this.route.queryParams.subscribe((params) => {
      this.filter.page = Number(params.page || 1);
      this.filter.pageSize = Number(params.per_page || 20);
      if (params.new_flag) {
        this.filter.new_flag = Number(params.new_flag) === 1;
      }
      if (params.free_shipping_flag) {
        this.filter.free_shipping_flag = Number(params.free_shipping_flag) === 1;
      }
      if (params.c_bunrui_cd) {
        this.filter.category = params.c_bunrui_cd;
      }
      if (params.q) {
        this.filter.q = params.q;
      }
      if (params.update_date === 'desc') {
        this.filter.sort = 'update_date';
      }
      if (params.arrival_date === 'desc') {
        this.filter.sort = 'arrival_date';
      }
      if (params.kakaku === 'asc') {
        this.filter.sort = 'price_up';
      }
      if (params.kakaku === 'desc') {
        this.filter.sort = 'price_down';
      }
      if (params.kakaku_low) {
        this.filter.kakaku_low = Number(params.kakaku_low);
      }
      if (params.kakaku_high) {
        this.filter.kakaku_high = Number(params.kakaku_high);
      }
      if (params.stars_division) {
        this.filter.stars_division = Number(params.stars_division) / 10;
      }
      if (params.star_rating === 'asc') {
        this.filter.sort = 'condition_up';
      }
      if (params.star_rating === 'desc') {
        this.filter.sort = 'condition_down';
      }
      if (params.shouhin_maker_id) {
        const ids = params.shouhin_maker_id.split(',');
        if (this.partmakers.length) {
          this.filter.partmaker = this.partmakers.filter((p) => ids.includes(p.name));
        } else {
          this.filter.partmaker = ids;
        }
      }
      if (params.s_bunrui_cd) {
        const ids = params.s_bunrui_cd.split(',');
        if (this.details.length) {
          this.filter.details = this.details.filter((p) => ids.includes(p.value));
        } else {
          this.filter.details = ids;
        }
      }
      if (params.tekigou_shashu_id) {
        this.filter.model = {
          name: params.tekigou_shashu_name,
          value: params.tekigou_shashu_id,
        };
        this.models.push(this.filter.model);
      }

      this.unsub();
      this.fetchSearch();
    });
  }

  fetchSearch(): void {
    this.loading = true;
    this.subscription = this.crooberSearchGQL
      .watch({
        path: window.location.search,
        categoryId: this.filter.category || '01',
      })
      .valueChanges.subscribe(
        (result) => {
          this.loading = false;
          console.log('Search response', result);

          if (result && result.data && result.data.crooberSearch) {
            this.items = result.data.crooberSearch.products;
            this.exchangeRate = result.data.exchangeRate;
            this.partmakers = result.data.crooberPartmakers.makerInitial;
            if (typeof this.filter.partmaker[0] === 'string') {
              this.filter.partmaker = this.partmakers.filter((p) =>
                this.filter.partmaker.includes(p.name),
              );
            }
            this.details = result.data.crooberPartmakers.detail || [];
            this.filter.details = this.details.filter((p) => {
              if (typeof this.filter.details[0] === 'string') {
                return this.filter.details.includes(p.value);
              } else {
                return this.filter.details.findIndex((d) => d.value === p.value) !== -1;
              }
            });

            this.total = Number(result.data.crooberSearch.total);

            this.title.setTitle(this.translate.instant('SEARCH.SEARCH'));
            this.googleTranslate.translate();
          } else {
            this.ns.renderError('Empty response');
          }
        },
        (error) => {
          this.loading = false;
          console.log(error);
          this.ns.renderError('Error', error);
        },
      );
  }

  changePage(page: number): void {
    scrollToTop();
    this.router.navigate([], {
      queryParams: {
        page,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePageSize(size: number): void {
    scrollToTop();
    this.router.navigate([], {
      queryParams: {
        per_page: size,
      },
      queryParamsHandling: 'merge',
    });
  }

  applyFilter(f: NgForm): void {
    this.router.navigate([], {
      queryParams: {
        new_flag: Number(f.value.new_flag),
        c_bunrui_cd: f.value.category ? f.value.category : null,
        s_bunrui_cd: f.value.detail ? f.value.detail.map((c) => c.value).join(',') : null,
        shouhin_maker_id: f.value.partmaker.length
          ? f.value.partmaker.map((p) => p.name).join(',')
          : null,
        shouhin_maker: f.value.partmaker.length
          ? f.value.partmaker.map((p) => p.value).join(',')
          : null,
        tekigou_shashu_id: f.value.model ? f.value.model.value : null,
        tekigou_shashu_name: f.value.model ? f.value.model.name : null,
        free_shipping_flag: Number(f.value.free_shipping_flag),
        stars_division: f.value.stars_division ? f.value.stars_division * 10 : null,
        kakaku_low: f.value.kakaku_low ? Number(f.value.kakaku_low) : null,
        kakaku_high: f.value.kakaku_high ? Number(f.value.kakaku_high) : null,
        ...this.getSortQuery(f.value),
        page: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  resetFilter(f: NgForm): void {
    f.reset();
    this.router.navigate([], {
      queryParams: {
        new_flag: null,
        free_shipping_flag: null,
        arrival_date: null,
        update_date: null,
        kakaku: null,
        kakaku_low: null,
        kakaku_high: null,
        stars_division: null,
        star_rating: null,
        page: null,
        c_bunrui_cd: null,
        shouhin_maker_id: null,
        shouhin_maker: null,
        tekigou_shashu_id: null,
        tekigou_shashu_name: null,
        s_bunrui_cd: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  unsub(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onMakerChange(makerId: string) {
    this.models = [];
    this.crooberCarTypes.fetch({ makerId }).subscribe((result) => {
      this.models = result.data.crooberCarTypes.vehicleName;
      this.googleTranslate.translate();
    });
  }

  ngOnDestroy(): void {
    this.unsub();
  }

  buy(item: any) {
    this.buying[item.code] = this.mhs.mutate(
      this.crooberOrderCreateGQL,
      {
        input: {
          itemId: item.code,
          quantity: 1,
          comment: '',
        },
      },
      {
        refetch: false,
        failureTranslationKey: 'AUCTION.LOT_BUY_FAIL',
        successTranslationKey: 'AUCTION.LOT_BUY_SUCCESS',
      },
    ).loading;
  }

  private getSortQuery(value) {
    return {
      arrival_date: value.sort === 'arrival_date' ? 'desc' : null,
      update_date: value.sort === 'update_date' ? 'desc' : null,
      kakaku: value.sort === 'price_up' ? 'asc' : value.sort === 'price_down' ? 'desc' : null,
      star_rating:
        value.sort === 'condition_up' ? 'asc' : value.sort === 'condition_down' ? 'desc' : null,
    };
  }
}
